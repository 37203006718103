export default {
	name: 'Questions',
	created () {
		this.$nextTick(function () {
			this.GetFrequentlyAskedQuestions()

		})
	},
	mounted () {

	},
	props: [],
	data () {
		return {
			QusetionsArr: [],
			NavSonArr: [],
			IssueConArr: [],
			NavId: 0,
			NavIdSon: null,
			ArrangeActive6: null,
			ArrangeActive6Of: false
		}
	},
	computed: {

	},
	watch: {

	},
	methods: {
		// 获取Questions的数据
		GetFrequentlyAskedQuestions: function () {
			let _this = this
			$.ajax({
				type: "post",
				url: process.env.VUE_APP_API + "GetFrequentlyAskedQuestions",
				data: { token: "webkey" },
				dataType: "json",
				success: data => {
					if (data.status === "SUCCESS") {
						let arr = []
						data.text.filter((c, i) => {
							if (data.text.findIndex(j => j.category == c.category) == i) {
								this.QusetionsArr.push(
									{
										id: i,
										secondLevel: false,
										name: c.category,
										issue: [],
									}
								)
							}
						})
						$.each(this.QusetionsArr, (j, q) => {
							$.each(data.text, (i, c) => {
								if (q.name == c.category) {
									if (c.question == 'Why am I not receiving emails from UP?') {
										q.issue.push(
											{
												issueName: c.question,
												answer: "If you&#39;re not receiving emails from us, please check your spam folder. If you find our emails there, mark them as &quot;Not Spam&quot; and ensure you whitelist our three email addresses: <br><br> <a href='mailto:investup.sg@cgsi.com'>investup.sg@cgsi.com</a>,<br><a href='mailto:hello-investup.sg@cgsi.com'>hello-investup.sg@cgsi.com</a>, and<br><a href='mailto:noreply-investup.sg@cgsi.com'>noreply-investup.sg@cgsi.com</a><br><br>The emails listed are for reference and may not be exhaustive. Whitelisting helps prevent future emails from being marked as spam, ensuring you receive important communications from us in your inbox."
											}
										)
									} else if (c.question == 'How do I make an inquiry?') {
										q.issue.push(
											{
												issueName: c.question,
												answer: "Our recommended approach for providing assistance is through the inquiry form within your UP trading app. Nevertheless, we are equally pleased to assist you through email at <a href='mailto:investup.sg@cgsi.com'>investup.sg@cgsi.com</a>"
											}
										)
									} else {
										q.issue.push(
											{
												issueName: c.question,
												answer: c.answer
											}
										)
									}
								}
							})
						})
						this.IssueConArr = this.QusetionsArr[0].issue
					}
				},
				error: function (error) {
					console.log(error);
				}
			});
		},
		NavCon: function (data) {
			this.NavSonArr = []
			this.IssueConArr = []
			this.NavId = data.id
			if (data.secondLevel) {
				this.NavSonArr = data.issue
			} else {
				this.IssueConArr = data.issue
			}
			this.ArrangeActive6Of = false
			$('#Questions').find('.Arrange').find('li').find('.Text_a').slideUp()
		},
		NavConSon: function (id, data) {
			this.IssueConArr = []
			this.NavIdSon = id
			this.IssueConArr = data.subitemIssue


		},
		ArrangeActive6Clcik (active, data) {
			if (data.answer.length !== 0) {
				this.ArrangeActive6 = active
				this.ArrangeActive6Of = !this.ArrangeActive6Of
				$('#Questions').find('.Arrange').find('li').eq(active).siblings().find('.Text_a').slideUp()
				$('#Questions').find('.Arrange').find('li').eq(active).find('.Text_a').slideToggle()
			}

		}
	}
}
